<template>
  <div id="downloadbox">
    <svg-icon name="appstore" class="btn" :size="this.size" />
    <svg-icon name="googleplay" class="googleplay" :size="this.size" />
  </div>
</template>

<script>
export default {
  name: 'DownloadBox',
  components: {

  },
  data () {
    return {

    }
  },
  props: {
    size: {
      type: String,
      default: "160"
    }
  },
  methods: {},
}
</script>

<style lang='scss' scoped>
#downloadbox {
  display: flex;
  align-items: center;
  max-height: 60px;
  .googleplay {
    margin-left: 20px;
  }
}
</style>
