import axios from 'axios'
import { ElMessage,ElMessageBox } from 'element-plus'
import 'element-plus/es/components/message/style/css'
import store from '../store'
import Cookies from 'js-cookie'

const instance = axios.create({
  // baseURL: "http://13.231.143.111:9119",
  baseURL: "http://125.199.5.137:9119",
  timeout: 10000
})


instance.interceptors.request.use(
  config =>{
    let userInfo = Cookies.get('userInfo')
    if(userInfo) {
      userInfo = JSON.parse(userInfo)
      config.headers['token'] = userInfo.token
    }
    return config
  },error =>{
    console.log(error)
    Promise.reject(error)
})


instance.interceptors.response.use(response =>{
  const res = response.data
  if(res.code !== 20000) {
    ElMessage({
      message: res.message,
      type: 'error',
      duration: 5 * 1000
    })
    // 50008:非法token 50023: 已经登陆 50014:token过期   
    if(res.code === 50008 || res.code === 50012 || res.code === 50014) {
      ElMessageBox.confirm(
        '已经登出，可以取消继续停留，或者重新登陆',
        '确定登出',
        {
          confirmButtonText:'重新登陆',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(()=>{
        store.dispatch('FedLogOut').then(()=>{
          location.reload() //重新实例化router 避免bug
        })
      })
    } else if(res.code === 28006 || res.code === 403) {
      window.location.href = '/'
    } else if(res.code === 28019) {
      window.location.href = '/locked'
    } else if(res.code === 28005 || res.code === 28020 || res.code === 20001 || res.code ===28003 || res.code === 28010 || res.code === 28000) {
      return res
    } else if(res === true || res === false){
      return res
    }
    // return Promise.reject(new Error(res.msg || 'Error'))
    return res
  } else{
    return response.data
  }
},error => {
  if (error.response.data.success == false) {
    return error.response.data
  } else if (error.response) {
    ElMessage({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject('Error')
  } 

  }
)
export default instance;

