import { createI18n } from 'vue-i18n'		//引入vue-i18n组件
  //注册i8n实例并引入语言文件
 const i18n = createI18n({
    locale: 'en-US',		//默认显示的语言 
    fallbackLocale: 'en-US',
      messages: {
        'zh-CN':require('./cn'),
        'ja-JP':require('./jp'),	//索引语言文件
        'en-US':require('./en'),
        'zh-TW':require('./cntw')
      }
  })
export default i18n;
