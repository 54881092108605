import { createStore } from 'vuex';
// import storage from "@/utils/storage"

export default createStore({
  //全局状态
  state: {
    marketStreamData:{},
    //凡是发送通知需要体现到navbar的小铃铛就在方法后改变这个值
    navNoticeNotReadCount:0,
    //默认主题选项
    themeType:"light",
    //当前聊天对象id
    currentChatId: '',
    //当前聊天对象
    currentChatUserInfo: {},
    //用户社交媒体信息
    userSocialInfo:{},
    // friendRequestNotice: {},
    //聊天快照列表
    snapshotList:{},
    //好友列表
    friendList: {},
    //用户基本信息
    userBaseInfo:{},
    //用户配置信息
    userProfile:{},
    //账户表格
    accountForm:{},
    //flash文字
    flashText:"",
    currentChat: { // 当前窗口信息
      who: '',
      name: ''
    },
    isNeedPush: 0, // 控制了解界面刷新显示消息
    currentChatMessage: [],
  },
  //加工State
  getters: {
  },
  //改变State状态
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
