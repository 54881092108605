
import request from "../request"

export function getCountry() { //验证
  return request({
    url: '/api/web/common/country/list',
    method: 'get',
  })
}

export function getLocation() { //验证
  return request({
    url: '/api/web/common/country/getLocation',
    method: 'get',
  })
}

export function getLocationByIp(ip) { //验证
  return request({
    url: '/api/web/common/country/getLocationByIp/' + ip,
    method: 'get',
  })
}

