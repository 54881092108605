<template>
  <div id="footbar">
    <div class="line1">
      <div class="logoBox">
        <svg-icon name="logo" size="40" class="logo" />
        <svg-icon name="logotext" size="40" class="logotext" />
      </div>

      <div class="downloadBox">
        <h4>{{ $t(`lang.footbar1`) }}:</h4>
        <download-box size="120" />
      </div>

      <div class="serviceBox">
        <p>{{ $t(`lang.footbar2`) }}: service@pinkbook.me</p>
      </div>
    </div>
    <div class="line2">
      <div class="menu">
        <div class="menuItem">{{ $t(`lang.footbar3`) }}</div>
        <div class="menuItem" @click="goPrivacy()">
          {{ $t(`lang.footbar4`) }}
        </div>
        <div class="menuItem">{{ $t(`lang.footbar5`) }}</div>
      </div>
      <div class="copy">
        ©Copyright {{ year }} dots All Rights Reserved by 佐治家株式會社.
      </div>
    </div>
  </div>
</template>

<script>
import DownloadBox from './DownloadBox.vue'
export default {
  name: 'FootBar',
  components: {
    DownloadBox

  },
  data () {
    return {
      year: ""
    }
  },
  methods: {
    getYear () {
      this.year = new Date().getFullYear()
    },
    goPrivacy () {
      this.$router.push("/privacy")
    }
  },
  created () {
    this.getYear()
  }
}
</script>

<style lang='scss' scoped>
#footbar {
  width: 100vw;
  height: 240px;
  background: linear-gradient(90deg, #ff99bb 0.01%, #ff699b 100%), #d9d9d9;
  padding: 0px 10%;
  z-index: 99;
  .line1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    padding: 10px 0;
    .logoBox {
      display: flex;
      align-items: center;
      min-width: 300px;
      .logotext {
        padding-left: 18px;
        transform: scale(5);
      }
    }
    .downloadBox {
      display: flex;
      align-items: center;
      h4 {
        margin-right: 20px;
      }
    }
    .serviceBox {
      text-align: start;
    }
  }
  .line2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    padding-top: 20px;
    .copy {
      font-size: 14px;
    }
    .menu {
      display: flex;
      align-items: center;
      .menuItem {
        margin-right: 40px;
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  #footbar {
    padding: 20px 20px !important;
    height: auto !important;
  }
  .line1 {
    display: block !important;
  }
  .downloadBox {
    margin-top: 20px;
    text-align: start;
    display: block !important;
  }
  .copy {
    padding-top: 20px;
    font-size: 10px !important;
  }
}
@media screen and (max-width: 910px) {
  .line2 {
    display: block !important;
  }
}
</style>
