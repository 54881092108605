export const lang = {
  //---------------------按鈕-------------------------
  back: 'Back',
  save: 'Save',
  //--------------------navbar------------------------
  navBarMenu1:' Home',
  navBarMenu2:' Community',
  navBarMenu3:' Download',
  navBarMenu4:' About',
  
  //-----------------------page1-----------------------
  page1Index1:' Know the diverse women in the world',
  page1Index2:' Multiple friendliness and respect',
  page1Index3:' A social networking platform for women, which allows you to speak freely and share your life.' ,
  page1Index4:' Multi-community, knowing the world',
  page1Index5:' Multiple topic activities, breaking the ice in one second',
  page1Index6:'7*24 hours strict verification mechanism, feel safe',
  page1Index7:' Download now',
  //-----------------------page2-----------------------
  page2Index1:' Open the pink book and bid farewell to being alone',
  page2Index2:' quick pairing',
  page2Index3:' enthusiastic live broadcast',
  page2Index4:' Opportunities around us',
  page2Index5:' Life Bits',
  page2Index6:' Select quick matching to seize opportunities',
  page2Index7:' courtship',
  page2Index8:' Only One',
  page2Index9:' Chinese',
  page2Index10:' Eng',
  page2Index11:' Leo',
  page2Index12:' Scorpio',
  //-----------------------Download-----------------------
  download1:' Download Pink Book immediately',
  download2: ' Click download or QR code scan',
  //-----------------------about-----------------------
  about1:' Pink Book Team is for you',
  about2:' Click download or QR code scan',
  aboutItem1: "Management Team Service",
  aboutItem2:"7*24 hours ",
  aboutItem3: "Answer questions and strictly review user information, and refuse male users or suspicious accounts",
  aboutItem4: "Building a Pluralistic Community",
  aboutItem5: "Infinite possibilities",
  aboutItem6: "Be friendly and tolerant of the community, create a peaceful community and enjoy life together",
  aboutItem7: "Focus on user experience",
  aboutItem8: "Made for you",
  aboutItem9: "Absorb the user experience, keep thinking and innovating, and strive for more opportunities",
  //-----------------------footbar-----------------------
  footbar1:' Download now',
  footbar2:' contact mailbox',
  footbar3:'Cookie policy',
  footbar4:' Privacy Policy',
  footbar5:' service agreement'

  
}
