export const lang = {
  //---------------------按鈕-------------------------
  back: 'Back',
  save: 'Save',
  //--------------------navbar------------------------
  navBarMenu1: '家',
  navBarMenu2: 'コミュニティ',
  navBarMenu3: 'ダウンロードAPP',
  navBarMenu4: '情報',
  
//-----------------------page1-----------------------
  page1Index1:'グローバルな女性に会う',
  page1Index2:'多様化 ・ フレンドリー ・ 尊敬',
  page1Index3:'為女性打造的社交交友平台，讓妳暢所欲言，分享生活的點滴。',
  page1Index4:'多元社群，認識全球',
  page1Index5:'多樣話題活動，一秒破冰',
  page1Index6:'7*24小時嚴格驗證機制，感受安全感',
  page1Index7:'立即下載',
//-----------------------page2-----------------------
  page2Index1:'打開粉書 告別孤單一人',
  page2Index2:'快速配對',
  page2Index3:'熱情直播',
  page2Index4:'身旁機遇',
  page2Index5:'生活點滴',
  page2Index6:'精選快速配對 抓準機會',
  page2Index7:'求愛',
  page2Index8:'非誠勿擾',
  page2Index9:'中文',
  page2Index10:'英文',
  page2Index11:'獅子座',
  page2Index12:'天蠍座',
  //-----------------------Download-----------------------
  download1:'立即下載粉書',
  download2:'點擊下載或QR code掃描',
  //-----------------------about-----------------------
  about1:'粉書團隊為您',
  about2:'點擊下載或QR code掃描',
  aboutItem1:"管理團隊服務",
  aboutItem2:"7*24小時",
  aboutItem3:"回答疑問以及嚴格審核用戶資訊，謝絕男士用戶或可疑帳號",
  aboutItem4:"建立多元社區",
  aboutItem5:"無限可能性",
  aboutItem6:"友善且包容社群，創造和平社區，一同享受生活",
  aboutItem7:"注重用戶體驗",
  aboutItem8:"為您打造",
  aboutItem9:"吸收用戶體驗不停思考和創新，爭取更多機會",
  //-----------------------footbar-----------------------
  footbar1:'立即下載',
  footbar2:'聯絡信箱',
  footbar3:'Cookie政策',
  footbar4:'隱私政策',
  footbar5:'服務協議',
}
