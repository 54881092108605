import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/privacy',
    name: 'PrivacPage',
    component: () => import('../views/Contact/PrivacyPolicy.vue')
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: () => import('../views/about/AboutPage.vue')
  },
  {
    path: '/download',
    name: 'DownloadPage',
    component: () => import('../views/download/DownloadPage.vue')
  },
  {
    path: '/community',
    name: 'CommunityPage',
    component: () => import('../views/community/CommunityPage.vue')
  },
  {
    path: '/flash/:id',
    name: 'FlashDetail',
    component: () => import('../views/community/flash/FlashDetail.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router