<template>
  <div id="app">
    <nav-bar class="nav" />
    <router-view v-slot="{ Component }">
      <keep-alive>
        <transition :name="animation">
          <component :is="Component" />
        </transition>
      </keep-alive>
    </router-view>
    <foot-bar />
  </div>
</template>


<script>
import NavBar from '@/components/NavBar.vue'
import { ref, watch } from 'vue'
import { useRoute } from "vue-router";
import FootBar from './components/FootBar.vue';
export default {
  name: 'App',
  components: {
    NavBar,
    FootBar
  },
  data () {
    return {

    }
  },

  setup () {
    const route = useRoute();
    const animation = ref('slide');
    watch(route, () => {
      animation.value = route.meta.index == 1 ? 'slide-right' : 'slide-left'
    })
    return {
      animation
    }
  },
  watch: {
    "$route" () {
      let allPath = this.$route.fullPath //取得當前網址
      let mainPath = allPath.slice(0, 18) //取得當前網址前18字元
      let anserArticleIds = allPath.substring(19) //取得當前網址19位之後的所有字元 即為ID
      if (mainPath == "/support/anserpage") {
        this.$router.push('/support/anserpage')
        if (anserArticleIds !== "") { //防止加載回/support/anserpage時刷新VUEX裡的anserArticleId為空
          this.$store.commit('upDate', { anserArticleId: anserArticleIds })
        }
      }
    }
  },
  created () {
    let storageKey = "themeType"
    let theme = localStorage.getItem(storageKey)
    if (theme == null) {
      localStorage.setItem(storageKey, "light")
      window.document.documentElement.setAttribute("data-theme", "light");
    } else {
      window.document.documentElement.setAttribute("data-theme", theme);
    }

  }
}
</script>

<style lang= "scss">
@import 'assets/scss/baseColor.scss';
@import 'assets/scss/handle.scss';
@import 'assets/scss/base.css';
@import 'assets/font/font.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  .nav {
    position: fixed;
    z-index: 1111;
    top: 0;
  }
}
</style>
