<template>
  <div class="navbody">
    <div class="phoneMenu" :class="{ phoneMenuOpen: phoneMenuOpen }">
      <div
        class="menuItem"
        v-for="item in gpsItems"
        :key="item"
        @click="goPath(item.path)"
      >
        <h4>{{ item.text }}</h4>
      </div>
      <div class="languageBox phonelangMenu">
        <el-dropdown>
          <span class="el-dropdown-link">
            <div class="langItem">
              <svg-icon
                :name="langIcon"
                size="30"
                color="#fff"
                class="langIcon"
              />
              {{ this.languageText }}
            </div>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                @click="chooseLanguage(item)"
                v-for="item in languageList"
                :key="item"
              >
                <div class="langItem">
                  <svg-icon
                    :name="item.icon"
                    size="30"
                    color="#fff"
                    class="langIcon"
                  />{{ item.text }}
                </div></el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="locationBox">
        <h5 v-if="this.location.country">
          {{ this.location.city }} / {{ this.location.country }}
        </h5>
        <h5 v-else>无法获取您的国家</h5>
      </div>
    </div>
    <div class="navbar" :class="{ navOpen: phoneMenuOpen, navOpen: navBg }">
      <div class="logoBox">
        <svg-icon name="logo" size="40" class="logo" />
        <svg-icon name="logotext" size="40" class="logotext" />
      </div>

      <div class="box">
        <div class="menuBox">
          <div
            class="menuItem"
            v-for="item in gpsItems"
            :key="item"
            @click="goPath(item.path)"
          >
            <h4>{{ item.text }}</h4>
          </div>
        </div>

        <div class="languageBox">
          <el-dropdown>
            <span class="el-dropdown-link">
              <div class="langItem">
                <svg-icon
                  :name="langIcon"
                  size="30"
                  color="#fff"
                  class="langIcon"
                />
                {{ this.languageText }}
              </div>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  @click="chooseLanguage(item)"
                  v-for="item in languageList"
                  :key="item"
                >
                  <div class="langItem">
                    <svg-icon
                      :name="item.icon"
                      size="30"
                      color="#fff"
                      class="langIcon"
                    />{{ item.text }}
                  </div></el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="locationBox">
          <h5 v-if="this.location.country">
            {{ this.location.city }} / {{ this.location.country }}
          </h5>
          <h5 v-else>无法获取您的国家</h5>
        </div>
      </div>
      <div class="phoneBox" @click="openMenu()">
        <svg-icon name="bars" size="30" color="#fff" />
      </div>
    </div>
  </div>
</template>

<script>
import { getLocationByIp } from '@/axios/api/common'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      location: "",
      currentLang: 'uk',
      phoneMenuOpen: false,
      navBg: false,
      languageList: [{ icon: "usa", locale: "en-US", text: "En" }, { icon: "japan", locale: "ja-JP", text: "Jp" }, { icon: "taiwan", locale: "zh-CN", text: "Cn" }],
      languageText: "En",
      langIcon: "usa"
    }
  },
  methods: {
    goPath (path) {
      this.$router.push(path)
      this.phoneMenuOpen = false
    },
    getUserLocation () {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(response => {
          this.results = response.ip;
        })
      getLocationByIp()
    },
    openMenu () {
      this.phoneMenuOpen = !this.phoneMenuOpen
    },
    scrollHandle (e) {
      let top = e.srcElement.scrollingElement.scrollTop;    // 获取页面滚动高度
      if (top > 120) {
        this.navBg = true
      } else {
        this.navBg = false
      }
    },
    chooseLanguage (item) {
      this.langIcon = item.icon
      this.$i18n.locale = item.locale
      this.languageText = item.text
      console.log(item.text)
    }
  },
  created () {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(response => {
        const ip = response.ip;
        getLocationByIp(ip).then(res => {
          this.location = res.data.list
        })
        if (this.location.country == "Japan") {
          this.langIcon = "japan"
          this.$i18n.locale = "ja-JP"
          this.languageText = "Jp"
        }
      })
  },
  mounted () {
    window.addEventListener('scroll', this.scrollHandle);//绑定页面滚动事件
  },
  computed: {
    gpsItems () {
      return [
        { text: this.$t('lang.navBarMenu1'), path: "/" },
        { text: this.$t('lang.navBarMenu2'), path: "/community" },
        { text: this.$t('lang.navBarMenu3'), path: "/download" },
        { text: this.$t('lang.navBarMenu4'), path: "/about" },
      ];
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.phonelangMenu {
  padding-top: 80%;
}
.navOpen {
  background: #ff99bb;
  width: 100vw !important;
}
.phoneMenu {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #ff99bb;
  height: 100vh;
  z-index: 99;
  top: 80px;
  transform: translateX(-100%);
  transition: 0.4s;
}
.phoneMenuOpen {
  transform: translateX(0%);
}
.langItem {
  display: flex;
  align-items: center;
}
.langIcon {
  margin-right: 10px;
}
.navbar {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 80px;
  padding: 0 10%;
  text-align: start;
  color: aliceblue;
  transition: 0.4s;
  .phoneBox {
    display: none;
    opacity: 0;
  }
  .logoBox {
    width: 50%;
    .logotext {
      padding-left: 18px;
      transform: scale(5);
    }
  }
  .box {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menuBox {
      min-width: 50%;
      display: flex;
      align-items: center;
      .menuItem {
        margin-right: 40px;
        cursor: pointer;
        min-width: 60px;
        transition: 0.4s;
        &:hover {
          color: rgb(97, 97, 78);
          transform: scale(1.2);
        }
      }
      .locationBox {
        margin-left: 40px;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .navbar {
    .logoBox {
      width: 40%;
    }
    .box {
      width: 60%;
    }
  }
}
@media screen and (max-width: 1090px) {
  .navbar {
    padding: 0 6%;
    .menuBox,
    .locationBox,
    .languageBox {
      display: none;
      opacity: 0;
      visibility: hidden;
    }
    .phoneBox {
      display: block;
      opacity: 1;
    }
  }
  .menuItem {
    height: 60px;
    font-size: 28px;
    color: #fff;
    line-height: 60px;
  }
}
</style>
